import React, { useEffect, useState } from "react";
import "./style.css";
import { Container } from "../../core/layout/styels"; // Corrected "styels" to "styles"
import { GiHamburgerMenu } from "react-icons/gi";
import { RiCloseFill } from "react-icons/ri";
import { motion } from "framer-motion";
import AutoForm from "../../core/form";
import { getData, postData } from "../../../backend/api";
import styled from "styled-components";
//import withLayout from "../layout";

const RegisterBtn = styled.button`
  background-color: red;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 10px;
  font-size: 14px;
  &.float {
    position: fixed;
    right: 20px;
    bottom: 20px;
    animation: blink 1s infinite;
    z-index: 1;
  }

  @keyframes blink {
    50% {
      opacity: 0.4;
    }
  }
  @media (max-width: 768px) {
    &.float {
      position: inherit;
      right: 20px;
      bottom: 20px;
    }
  }
`;

const onChange1 = (nameOfCenter, updateValue) => {
  const { studentsCountMale, studentsCountFemale, centerType } = updateValue;
  const male = parseFloat(studentsCountMale) || 0;
  const female = parseFloat(studentsCountFemale) || 0;
  const total = centerType === "Mixed" ? male + female : 0;
  updateValue["studentsCountTotal"] = total.toFixed(0);
  return updateValue;
};

function Header(props) {
  const [openMenuSetup, setOpenMenuSetup] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [openAffiliation, setOpenAffiliation] = useState(false);
  const [openHallTicket, setOpenHallTicket] = useState(false);
  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const [isFloating, setIsFloating] = useState(window.matchMedia("(max-width: 600px)").matches);

  useEffect(() => {
    const handleResize = () => {
      setIsFloating(window.matchMedia("(max-width: 600px)").matches);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenu = (e) => {
    e.preventDefault();
    setShowMenu(!showMenu);
  };

  const toggleHelpPopup = () => {
    setShowHelpPopup(!showHelpPopup);
    setShowMenu(false);
  };

  const formInput = [
    {
      type: "title",
      title: "Center Details",
      add: true,
    },
    {
      type: "text",
      placeholder: "Name Of Center",
      name: "nameOfCenter",
      validation: "",
      default: "",
      label: "Name Of Center",
      required: true,
      add: true,
    },
    {
      type: "select",
      apiType: "CSV",
      selectApi: "Male,Female,Mixed",
      placeholder: "Center Type",
      updateOn: "centerType",
      name: "centerType",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: false,
      label: "Center Type",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "district/select",
      placeholder: "District",
      name: "district",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: false,
      label: "District",
      required: true,
      view: true,
      add: true,
      update: true,
      search: true,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "area/get-area-by-district",
      placeholder: "Area",
      updateOn: "district",
      name: "area",
      validation: "",
      showItem: "userDisplayName",
      default: "",
      tag: false,
      label: "Area",
      required: true,
      view: true,
      add: true,
      update: true,
      search: true,
      filter: true,
    },
    {
      type: "text",
      placeholder: "Halqa Name",
      name: "halqaName",
      validation: "",
      default: "",
      label: "Halqa Name",
      required: true,
      add: true,
    },
    {
      type: "title",
      title: "Students Details",
      add: true,
    },
    {
      type: "number",
      placeholder: "Male Students",
      name: "studentsCountMale",
      validation: "",
      default: 0,
      label: "Male Students",
      required: true,
      add: true,
      onChange: onChange1,
      condition: {
        item: "centerType",
        if: "Female",
        then: "disabled",
        else: "enabled",
      },
    },
    {
      type: "number",
      placeholder: "Female Students",
      name: "studentsCountFemale",
      validation: "",
      default: 0,
      label: "Female Students",
      required: true,
      add: true,
      onChange: onChange1,
      condition: {
        item: "centerType",
        if: "Male",
        then: "disabled",
        else: "enabled",
      },
    },
    {
      type: "number",
      placeholder: "Total Students",
      name: "studentsCountTotal",
      validation: "",
      default: 0,
      label: "Total Students",
      required: true,
      add: true,
      onChange: onChange1,
      condition: {
        item: "centerType",
        if: "Mixed",
        then: "enabled",
        else: "disabled",
      },
      update: true,
    },
    {
      type: "title",
      title: "Co Ordinator Details",
      add: true,
    },
    {
      type: "text",
      placeholder: "Area Qsc Co Ordinator Name",
      name: "AreaQscCoOrdinatorName",
      validation: "",
      default: "",
      label: "Area Qsc Co Ordinator Name",
      required: true,
      add: true,
    },
    {
      type: "text",
      placeholder: "Mobile Number Of Co Ordinator",
      name: "mobNumberOfAreaQscCoOrdinator",
      validation: "",
      default: "",
      label: "Mobile Number Of Co Ordinator",
      required: true,
      add: true,
    },
  ];

  const formReg = [
    {
      type: "title",
      title: "Basic Information",
      add: true,
    },
    {
      type: "text",
      placeholder: "Name Of Applicant",
      name: "nameOfApplicant",
      validation: "",
      default: "",
      label: "Name Of Applicant",
      update: true,
      required: true,
      add: true,
    },
    {
      type: "select",
      placeholder: "Gender",
      name: "gender",
      validation: "",
      default: "",
      tag: true,
      label: "Gender",
      showItem: "Gender",
      required: true,
      view: true,
      filter: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Male,Female",
    },
    {
      type: "info",
      content: "നിങ്ങളുടെ 10 അക്ക മൊബൈൽ നമ്പർ ടൈപ്പ് ചെയ്യുക,ഹാൾ ടിക്കറ്റ് ഡൗൺലോഡ് ചെയ്യുന്ന സമയത്ത് നൽകേണ്ടതിനാൽ ഈ നമ്പർ നോട്ട് ചെയ്തു വെക്കുക. ",
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Mobile Number",
      name: "mobileNumber",
      validation: "",
      default: "",
      label: "Mobile Number",
      tag: true,
      required: true,
      view: true,
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Whatsapp Number",
      name: "whatsappNumber",
      validation: "",
      default: "",
      label: "Whatsapp Number",
      tag: true,
      required: true,
      add: true,
      view: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Address",
      name: "address",
      validation: "",
      default: "",
      label: "Address",
      tag: true,
      required: true,
      add: true,
      view: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Age",
      name: "age",
      validation: "",
      default: "",
      label: "Age",
      tag: true,
      required: true,
      add: true,
      view: true,
    },
    {
      type: "title",
      title: "Qualification Details",
      add: true,
    },
    {
      type: "textarea",
      placeholder: "Educational Qualification",
      name: "educationalQualification",
      validation: "",
      default: "",
      label: "Educational Qualification",
      tag: true,
      required: true,
      add: true,
      view: true,
      update: true,
    },
    {
      type: "textarea",
      placeholder: "Qualification in Islamic Education",
      name: "religiousEducationalQualification",
      validation: "",
      default: "",
      label: "Qualification in Islamic Education",
      tag: true,
      required: true,
      add: true,
      view: true,
      update: true,
    },
    {
      type: "title",
      title: "Exam Details",
      add: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "exam-type/select",
      placeholder: "Name of Exam Appearing Now",
      updateOn: "",
      name: "nameOfExamAppearingNow",
      collection: "nameOfExamAppearingNow",
      validation: "",
      showItem: "examType",
      search: false,
      default: "",
      tag: true,
      label: "Name of Exam Appearing Now",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "district/select",
      placeholder: "District",
      name: "district",
      collection: "district",
      validation: "",
      showItem: "district",
      default: "",
      tag: true,
      label: "District",
      search: false,
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "area/get-area-by-district",
      placeholder: "Area",
      updateOn: "district",
      name: "area",
      collection: "area",
      search: false,
      validation: "",
      showItem: "area",
      default: "",
      tag: true,
      label: "Area",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
    },
    {
      type: "info",
      content: "ഖുർആൻ സ്റ്റഡി സെന്റർ കേരളയിൽ അഫിലിയേറ്റ് ചെയ്തിട്ടുള്ള പ്രാദേശിക സെന്ററുകളിൽ പഠിക്കുന്നവർ Regular വിഭാഗത്തിലും അല്ലാത്തവർ Private വിഭാഗത്തിലും ഉൾപ്പെടുന്നു.",
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "Mode of Study",
      name: "status",
      validation: "",
      default: "",
      tag: true,
      label: "Mode of Study",
      showItem: "",
      required: true,
      view: true,
      info: "ഖുർആൻ സ്റ്റഡി സെന്റർ കേരളയിൽ അഫിലിയേറ്റ് ചെയ്തിട്ടുള്ള പ്രാദേശിക സെന്ററുകളിൽ പഠിക്കുന്നവർ Regular വിഭാഗത്തിലും അല്ലാത്തവർ Private വിഭാഗത്തിലും ഉൾപ്പെടുന്നു.",
      filter: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Private,Regular",
    },
    {
      type: "info",
      content: "റഗുലർ സെൻ്ററുകളിൽ  പഠിക്കുന്നവർ തങ്ങളുടെ സെൻ്ററിൻ്റെ പേര് തെരഞ്ഞെടുക്കുക.",
      add: true,
      update: true,
      condition: {
        item: "status",
        if: "Regular",
        then: "enabled",
        else: "disabled",
      },
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "center-registration/center-by-district",
      placeholder: "Study Center",
      updateOn: "area",
      name: "CenterRegistration",
      collection: "area",
      validation: "",
      showItem: "nameOfCenter",
      default: "",
      condition: {
        item: "status",
        if: "Regular",
        then: "enabled",
        else: "disabled",
      },
      tag: true,
      label: "Study Center",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
      search: false,
    },
    {
      type: "text",
      placeholder: "Affiliation  Number",
      updateOn: "",
      name: "affiliation",
      collection: "affiliation",
      validation: "",
      showItem: "",
      default: "",
      condition: {
        item: "status",
        if: "Regular",
        then: "enabled",
        else: "disabled",
      },
      tag: true,
      label: "Affiliation  Number",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
      search: false,
    },
    {
      type: "info",
      content: "പരീക്ഷ എഴുതാൻ ഉദ്ദേശിക്കുന്ന കേന്ദ്രം തെരഞ്ഞെടുക്കുക",
      add: true,
      update: true,
    },
    {
      type: "select",
      placeholder: "നിങ്ങളുടെ ജില്ലയിൽ തന്നെ അല്ലെ പരീക്ഷ എഴുതുന്നത്?",
      name: "outsideCenter",
      validation: "",
      default: "",
      tag: true,
      label: "നിങ്ങളുടെ ജില്ലയിൽ തന്നെ അല്ലെ പരീക്ഷ എഴുതുന്നത്?",
      showItem: "",
      required: true,
      view: true,
      filter: true,
      add: true,
      update: true,
      apiType: "CSV",
      selectApi: "Yes,No",
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "exam-center-registration/center-by-district",
      placeholder: "Exam Center",
      updateOn: "district",
      name: "examCenter",
      collection: "examCenter",
      showItem: "centerName",
      condition: {
        item: "outsideCenter",
        if: "Yes",
        then: "enabled",
        else: "disabled",
      },
      validation: "",
      default: "",
      tag: true,
      label: "Exam Center",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
      search: false,
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "district/select",
      placeholder: "Exam District",
      name: "examDistrict",
      collection: "examDistrict",
      validation: "",
      showItem: "district",
      default: "",
      tag: true,
      label: "Exam എഴുതാൻ ഉദ്ദേശിക്കുന്ന ജില്ല തെരഞ്ഞെടുക്കുക",
      search: false,
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
      condition: {
        item: "outsideCenter",
        if: "No",
        then: "enabled",
        else: "disabled",
      },
    },
    {
      type: "select",
      apiType: "API",
      selectApi: "exam-registration/district-center",
      placeholder: "Exam Center",
      updateOn: "examDistrict",
      name: "outsideExamCenter",
      collection: "outsideExamCenter",
      showItem: "centerName",
      condition: {
        item: "outsideCenter",
        if: "No",
        then: "enabled",
        else: "disabled",
      },
      validation: "",
      default: "",
      tag: true,
      label: "Exam Center",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
      search: false,
    },
    {
      type: "text",
      placeholder: "Reg No",
      name: "regno",
      validation: "",
      default: "",
      tag: true,
      label: "Reg No",
      required: true,
      view: true,
      add: true,
      update: true,
      filter: true,
      search: false,
    },
    {
      type: "title",
      title: "Payment Information",
      add: true,
    },
    {
      type: "info",
      content: "Regular വിഭാഗത്തിന് 100 രൂപയും Private വിഭാഗത്തിന് 150 രൂപയുമാണ് രജിസ്ട്രേഷൻ ഫീസ്. തുക 7994162608 നമ്പറിൽ ഗൂഗിൾ പേ ചെയ്യുക. ഏത് നമ്പറിൽ നിന്നാണോ ഗൂഗിൾ പേ ചെയ്തത്, ആ നമ്പർ ഇവിടെ നൽകുക.",
      add: true,
      update: true,
    },
    {
      type: "number",
      placeholder: "Gpay Number",
      name: "feeDetails",
      validation: "",
      default: "",
      info: "Regular വിഭാഗത്തിന് 100 രൂപയും Private വിഭാഗത്തിന് 150 രൂപയുമാണ് രജിസ്ട്രേഷൻ ഫീസ്. തുക 7994162608 നമ്പറിൽ ഗൂഗിൾ പേ ചെയ്യുക. ഏത് നമ്പറിൽ നിന്നാണോ ഗൂഗിൾ പേ ചെയ്തത്, ആ നമ്പർ ഇവിടെ നൽകുക.",
      label: "Gpay Number",
      required: true,
      add: true,
      view: true,
    },
  ];

  const hallTicket = [
    {
      type: "info",
      content: "Enter Mobile Number to download Hall Ticket",
    },
    // {
    //   type: "text",
    //   placeholder: "Hall Ticket Number",
    //   name: "hallTicketNumber",
    //   validation: "",
    //   default: "",
    //   label: "Hall Ticket Number",
    //   required: true,
    //   add: true,
    // },
    {
      type: "number",
      placeholder: "Mobile Number",
      name: "mobileNumber",
      validation: "",
      default: "",
      label: "Mobile Number",
      required: true,
      add: true,
    },
  ];

  const isCreatingHandler = (value, callback) => {
    setOpenMenuSetup(false);
    setOpenAffiliation(false);
    setOpenHallTicket(false);
  };

  const submitHallticket = async (post) => {
    props.setLoaderBox(true);
    try {
      const response = await postData({ ...post }, "hall-ticket/download");

      if (response && (response.data?.success || response.success === true)) {
        setOpenHallTicket(false);
        props.setLoaderBox(false);
        props.setMessage({
          type: 3,
          content: "Downloaded Successfully",
          proceed: "Okay",
        });
        window.open(process.env.REACT_APP_CDN + response.data.url, "_blank");
      } else {
        props.setLoaderBox(false);
        props.setMessage({
          type: 3,
          content: "User not registered for the exam",
          proceed: "Okay",
        });
      }
    } catch (error) {
      props.setLoaderBox(false);
      props.setMessage({
        type: 3,
        content: "An error occurred while submitting the hall ticket",
        proceed: "Okay",
      });
    }
  };

  const submitChange = async (post) => {
    postData({ ...post }, "center-registration").then((response) => {
      if (response.data.success === true) {
        setOpenAffiliation(false);
        props.setMessage({
          type: 1,
          content: "Submitted Successfully",
          proceed: "Okay",
        });
      }
    });
  };

  const submitReg = async (post) => {
    postData({ ...post }, "exam-registration").then((response) => {
      if (response.data.success === true) {
        console.log("hello", response.data.success);
        // setOpenMenuSetup(false);
        // Show success alert
        // alert("Submitted Successfully");
        // props.setMessage({
        //   type: 1,
        //   content: "Submitted Successfully",
        //   proceed: "Okay",
        // });

        props.setMessage({
          type: 2,
          content: "Submitted Successfully",
          proceed: "Okay",
          okay: "Cancel",
          onClose: async () => {
            try {
              setOpenMenuSetup(false);
            } catch (error) {}
          },
          onProceed: async () => {
            try {
              setOpenMenuSetup(false);
              // console.log("hai");
            } catch (error) {}
          },
          data: { id: 1 },
        });
      }
    });
  };

  const [showCenterRegistration, setCenterRegistration] = useState(false);
  const [showHallTicket, setHallTicket] = useState(false);
  const [showExamRegistration, setExamRegistration] = useState(false);
  const [showDownloads, setDownloads] = useState(false);
  const [showAboutUs, setAboutUs] = useState(false);
  const [showCertificate, setCertificate] = useState(false);

  const floatMenuSettings = async () => {
    getData({}, "floating-menu-settings").then((response) => {
      console.log({ response });
      if (response.data.success === true) {
        console.log(response.data.response);

        setCenterRegistration(response.data.response[0].centerRegistration);
        setHallTicket(response.data.response[0].hallTicket);
        setExamRegistration(response.data.response[0].examRegistration);
        setDownloads(response.data.response[0].downloads);
        setAboutUs(response.data.response[0].about);
        setCertificate(response.data.response[0].certificate);
      }
    });
  };

  floatMenuSettings();

  return (
    <>
      <header className="landing-sticky">
        <a href="/">
          {" "}
          <div className="logo">
            <img src={"/static/media/logo.cabad83f83216010b272.png"} alt="Company Logo" />
          </div>
        </a>

        <div className="landing-navbar">
          <a href="/">Home</a>
          {showCenterRegistration && (
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault(); // Prevent default behavior of anchor tag
                setOpenAffiliation(true);
                setShowMenu(false);
              }}
            >
              Centre Affiliation
            </a>
          )}
          {showCertificate && <a href="/">Certificate</a>}
          {showDownloads && <a href="/question-papers">Downloads</a>}
          {showAboutUs && <a href="/about-us">About Us</a>}
          <a href="/result">Result</a>
          {showHallTicket && (
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault(); // Prevent default behavior of anchor tag
                setOpenHallTicket(true);
                setShowMenu(false);
              }}
            >
              Hall Ticket
            </a>
          )}
        </div>

        {showMenu ? <RiCloseFill className="landing-hamburger" onClick={handleMenu} /> : <GiHamburgerMenu className="landing-hamburger" onClick={handleMenu} />}
        {showMenu && (
          <motion.div initial={{ left: "-100px", opacity: 0 }} animate={{ left: 0, opacity: 1 }} transition={{ duration: 0.1 }} className="landing-mobile-nav">
            <a href="/">Home</a>
            {showCenterRegistration && (
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default behavior of anchor tag
                  setOpenAffiliation(true);
                  setShowMenu(false);
                }}
              >
                Centre Affiliation
              </a>
            )}
            {showCertificate && <a href="/">Certificate</a>}
            {showDownloads && <a href="/question-papers">Downloads</a>}
            {showAboutUs && <a href="/about-us">About Us</a>}
            <a href="/result">Result</a>
            {showHallTicket && (
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default behavior of anchor tag
                  setOpenHallTicket(true);
                  setShowMenu(false);
                }}
              >
                Hall Ticket
              </a>
            )}
            {showExamRegistration && (
              <button
                className="landing-mob-register-btn"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default behavior of anchor tag
                  setOpenMenuSetup(true);
                  setShowMenu(false);
                }}
              >
                Exam Registration 2024
              </button>
            )}
            <button className="landing-mob-register-btn" onClick={toggleHelpPopup}>
              Help
            </button>
          </motion.div>
        )}
        {isFloating && showExamRegistration && (
          <RegisterBtn
            className="float"
            onClick={(e) => {
              e.preventDefault(); // Prevent default behavior of anchor tag
              setOpenMenuSetup(true);
              setShowMenu(false);
            }}
          >
            Exam Registration
          </RegisterBtn>
        )}
        {showExamRegistration && (
          <button
            className="landing-register-btn"
            onClick={(e) => {
              e.preventDefault(); // Prevent default behavior of anchor tag
              setOpenMenuSetup(true);
              setShowMenu(false);
            }}
          >
            Exam Registration 2024
          </button>
        )}
        <button className="landing-register-btn" onClick={toggleHelpPopup}>
          Exam Instructions
        </button>
      </header>

      <Container className="noshadow">
        {openMenuSetup && <AutoForm useCaptcha={false} key={"form"} formType={"post"} header={"Registration"} description={"ഖുർആൻ സ്റ്റഡി സെന്റർ കേരള 2024 ജൂലൈ 21 ന് നടത്തുന്ന വാർഷിക പരീക്ഷക്ക് രെജിസ്റ്റർ ചെയ്യാനുള്ള ഫോം"} css="plain embed" formInput={formReg} submitHandler={submitReg} button={"Submit"} isOpenHandler={isCreatingHandler} isOpen={true} plainForm={false} formMode={"single"}></AutoForm>}
        {openAffiliation && <AutoForm useCaptcha={false} key={"form"} formType={"post"} header={"Centre Affiliation"} description={"പ്രാദേശിക തലങ്ങളിൽ പ്രവർത്തിക്കുന്ന ഖുർആൻ പഠന വേദികൾ, ഖുർആൻ സ്റ്റഡി സെന്റർ കേരളയിൽ അഫിലിയേറ്റ് ചെയ്യുന്നതിനുള്ള ഫോം."} css="plain embed" formInput={formInput} submitHandler={submitChange} button={"Submit"} isOpenHandler={isCreatingHandler} isOpen={true} plainForm={false} formMode={"single"}></AutoForm>}
        {openHallTicket && <AutoForm useCaptcha={false} key={"form"} formType={"post"} header={"Hall Ticket Download"} description={"രജിസ്ട്രേഷൻ സമയത്ത് നൽകിയ മൊബൈൽ നമ്പർ താഴെ നൽകി സബ്മിറ്റ് ചെയ്യുക."} css="plain embed" formInput={hallTicket} submitHandler={submitHallticket} button={"Submit"} isOpenHandler={isCreatingHandler} isOpen={true} plainForm={false} formMode={"single"}></AutoForm>}
        {showHelpPopup && (
          <div className="help-popup">
            <div className="help-content">
              <h3>നിര്‍ദ്ദേശങ്ങള്‍</h3>
              <p>ഖുർആൻ സ്റ്റഡി സെന്റർ കേരള 2024 ജൂലൈ 21 ന് സംഘടിപ്പിക്കുന്ന വാർഷിക പരീക്ഷയുടെ രജിസ്ട്രേഷൻ ഫോം ആണിത്.താഴെ നൽകിയ നിർദേശങ്ങൾ വായിച്ച ശേഷം ഫോം ഫിൽ ചെയ്യുക നിർദേശങ്ങൾ </p>
              <p>1.ഖുർആൻ സ്റ്റഡി സെന്റർ കേരളയിൽ അഫിലിയേറ്റ് ചെയ്തിട്ടുള്ള പ്രാദേശിക സ്റ്റഡി സെന്ററുകളിൽ പഠിച്ചു പരീക്ഷ എഴുതുന്നവർ റഗുലർ വിഭാഗത്തിലും സ്വന്തമായി പഠിച്ചു പരീക്ഷ എഴുതുന്നവർ പ്രൈവറ്റ് വിഭാഗത്തിലുമാണ് രജിസ്റ്റർ ചെയ്യേണ്ടത്. ഫോമിൽ Mode of Study എന്ന ഓപ്ഷനിൽ Private/ Regular തെരഞ്ഞെടുക്കുക.</p>
              <p>2.പ്രിലിമിനറി രണ്ടാം വർഷത്തിൽ ഇത്തവണ Preliminary II New(പുതിയ സിലബസ്), Preliminary II Old(പഴയ സിലബസ്) എന്നിങ്ങനെ രണ്ട് പരീക്ഷകൾ ഉണ്ട്. നിശ്ചിത കോളത്തിൽ, അവ ഉൾപ്പെടെയുള്ള പ്രിലിമിനറി ,സെക്കൻഡറി പരീക്ഷകളുടെ ലിസ്റ്റിൽ നിന്ന് നിങ്ങൾ എഴുതുന്നത് തെരഞ്ഞെടുക്കേണ്ടതാണ്.</p>
              <p>3.റഗുലർ വിഭാഗത്തിൽ ഉള്ളവർ തങ്ങൾ പഠിക്കുന്ന സെന്ററിന്റെ പേര് Study Centre ഓപ്ഷനിലെ ലിസ്റ്റിൽ നിന്ന് തെരഞ്ഞെടുക്കുക.നിശ്ചിത കോളത്തിൽ അഫിലിയേഷൻ നമ്പർ കോളത്തിൽ ചേർക്കണം.</p>
              <p>4.നിങ്ങൾ പഠിക്കുന്ന പ്രാദേശിക സെന്ററിന്റെ പേര് ലിസ്റ്റിൽ ഇല്ലെങ്കിൽ Area QSC കോഡിനേറ്ററെ അറിയിക്കേണ്ടതാണ്. അവർ മുഖേന അഫിലിയേഷൻ വിവരങ്ങൾ ലഭ്യമാകുന്നതാണ്.</p>
              <p>5.റഗുലർ വിഭാഗത്തിൽ 100 രൂപയും പ്രൈവറ്റ് വിഭാഗത്തിൽ 150 രൂപയും ആണ് ഫീസ്. 7994162608 എന്ന നമ്പറിലേക്ക് ഗൂഗിൾ പേ ചെയ്യുക. ഏത് നമ്പറിൽ നിന്നാണോ ചെയ്തത്, ആ നമ്പർ നൽകിയ ശേഷമാണ് സബ്മിറ്റ് ചെയ്യേണ്ടത്. കോഡിനേറ്റർ QSC കേരള 7994162608</p>
              <button onClick={toggleHelpPopup} style={{ marginTop: "10px" }}>
                Close
              </button>
            </div>
          </div>
        )}
      </Container>
    </>
  );
}

export default Header;
